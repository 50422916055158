import React, {useEffect } from "react";
import Layout from "../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import Masvistos from "../components/masvistos";
import Noticias from "../components/noticias";
import Newsletter from "../components/newsletter";

const Noticia = () => {
    const seo = {
      metaTitle: "Toda la actualizadad del mundo saludable",
      metaDescription: "Ponte al día con las todas novedades de nutrición y cocina",
      shareImage: ""
    };

  return (
    <Layout seo={seo}>
        
        <div className="grid grid-cols-1 gap-4 md:gap-6 md:grid-cols-3 sm:px-6 lg:max-w-5xl lg:px-0 mx-auto">
            <div className="py-0  md:col-span-2 ...">
                <h1 className="max-w-3xl m-auto poppins  pt-2 md:text-s38 text-s33 px-4 leading-8 md:leading-11 pb-6 text-black text-center">Lo último en salud</h1>
                <Noticias />
            </div>
            <div className="px-4 md:pl-4 md:ml-2 md:border-l md:pr-10 md:mt-16">
                <div className="poppins font-medium text-25 sm:pt-2 leading-7 pb-2">¡Vamos a cocinar!</div>
                <div className="archebook text-20 text-gristexto">Si lo que te falta es motivación para poner en marcha tu cambio de hábitos en este espacio encontrarás consejos y recomendaciones para que empieces a poner en acción. Además, ponte al día con lo más nuevo que ocurre en el mundillo saludable: desde alimentación, empresas, colaboraciones hasta electrodomésticos que nos cambian la vida a mejor.</div>
                <div className="text-marron poppins w500 bg-amarillo text-xl text-center py-3 mt-8">LO MÁS VISTO</div>
                <div className="pl-0 pr-10 md:pr-2">
                    <Masvistos/>
                </div>
            </div>
        </div>
        <Newsletter />
    </Layout>
  );
};

export default Noticia;